import * as React from "react"
import { cn } from "../../lib/utils"

const AccordionContext = React.createContext({
  isOpen: false,
  toggleOpen: () => {},
})

const Accordion = React.forwardRef(({ className, children, defaultValue, ...props }, ref) => {
  const [openItem, setOpenItem] = React.useState(defaultValue)

  return (
    <div 
      ref={ref} 
      className={cn("space-y-1 bg-black/80 backdrop-blur-sm rounded-lg border border-gray-800", className)}
      {...props}
    >
      <AccordionContext.Provider value={{ openItem, setOpenItem }}>
        {children}
      </AccordionContext.Provider>
    </div>
  )
})
Accordion.displayName = "Accordion"

const AccordionItem = React.forwardRef(({ className, value, children, ...props }, ref) => {
  const { openItem } = React.useContext(AccordionContext)
  const isOpen = openItem === value

  return (
    <div 
      ref={ref} 
      className={cn("border-b border-gray-700", className)}
      data-state={isOpen ? "open" : "closed"}
      {...props}
    >
      {children}
    </div>
  )
})
AccordionItem.displayName = "AccordionItem"

const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => {
  const { openItem, setOpenItem } = React.useContext(AccordionContext)
  const itemValue = props['data-value']
  const isOpen = openItem === itemValue

  const handleClick = () => {
    setOpenItem(isOpen ? null : itemValue)
  }

  return (
    <div className="flex items-center justify-between py-4 px-6">
      <span className="text-xl font-anta select-none">{children}</span>
      <button
        ref={ref}
        onClick={handleClick}
        className={cn(
          "ml-2 p-2 hover:bg-gray-800 rounded-full transition-colors",
          className
        )}
        {...props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4 shrink-0 transition-transform duration-200"
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
    </div>
  )
})
AccordionTrigger.displayName = "AccordionTrigger"

const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => {
  const { openItem } = React.useContext(AccordionContext)
  const itemValue = props['data-value']
  const isOpen = openItem === itemValue

  return (
    <div
      ref={ref}
      className={cn(
        "overflow-hidden transition-all duration-300",
        isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0",
        className
      )}
      {...props}
    >
      <div className="px-6 pb-4">{children}</div>
    </div>
  )
})
AccordionContent.displayName = "AccordionContent"

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent } 