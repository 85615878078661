import React from "react";
import { Separator } from "../components/ui/separator";

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <Separator className="mb-4" />
      <div className="container mx-auto px-4 py-6 flex justify-center items-center">
        <p className="text-sm text-gray-400">Buffer © {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
