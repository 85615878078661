import React, { useEffect, useState } from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

// Static projects
const staticProjects = [
  {
    title: "Discord Bot: Sanz (SHUTDOWNED)",
    description: "An interactive Discord chatbot that can entertain you with a few commands.",
    getImageSrc: () => require("../images/Sanz.png"),
    link: "https://sanz.bufferization.com",
  },
  {
    title: "AzureS (Roblox Colorbot External)",
    description: "A Roblox colorbot external aimbot (PAID)",
    getImageSrc: () => require("../images/azure.png"),
    link: "https://discord.gg/9VJuNutke6"
  },
  {
    title: "CPR-Press",
    description: "A media compressor tool that bypasses any copyright detections",
    getImageSrc: () => require("../images/cpr.png"),
    link: "https://phcorner.net/threads/cpr-press-remove-copyright-infringement-in-a-video-by-compressing-it-2024.2155525/"
  },
];

const ProjectsSection = () => {
  const [allProjects, setAllProjects] = useState([...staticProjects]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRustyLibraries = async () => {
      try {
        const response = await fetch('https://api.github.com/orgs/rusty-libraries/repos');
        const repos = await response.json();

        // Filter out repos that might already exist in static projects
        const newRepos = repos.filter(repo => 
          !staticProjects.some(staticProject => staticProject.title.toLowerCase() === repo.name.toLowerCase())
        ).map(repo => ({
          title: repo.name,
          description: repo.description || "No description available",
          getImageSrc: () => require("../images/rs.png"), // Default Rust image
          link: repo.html_url
        }));

        setAllProjects([...staticProjects, ...newRepos]);
      } catch (error) {
        console.error("Error fetching repositories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRustyLibraries();
  }, []);

  return (
    <FullScreenSection
      backgroundColor="black"
      backgroundImage="https://raw.githubusercontent.com/24rr/tmpignore/main/bg.png"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
      fontFamily="Anta"
      id="projects-section"
      paddingTop="80px"
      minHeight={"100vh"}
    >
      <Heading as="h1" fontFamily="anta" fontWeight={400}>
        My Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, minmax(0, 1fr))" }}
        gridGap={8}
      >
        {loading ? (
          <div className="col-span-3 text-center text-white">Loading projects...</div>
        ) : (
          allProjects.map((project) => (
            <Card
              key={project.title}
              title={project.title}
              description={project.description}
              imageSrc={project.getImageSrc()}
              link={project.link}
            />
          ))
        )}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
