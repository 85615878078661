import React from "react";
import {
  Card as ShadCard,
  CardHeader,
  CardContent,
  CardFooter,
} from "../components/ui/card";
import { HStack, Image } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Card = ({ title, description, imageSrc, link }) => {
  return (
    <ShadCard className="bg-black border-cyan-500 hover:border-cyan-400 transition-colors">
      <CardHeader className="p-0">
        <Image
          src={imageSrc}
          alt={title}
          className="w-full h-48 object-cover rounded-t-lg"
        />
      </CardHeader>
      
      <CardContent className="p-4 space-y-4">
        <h3 className="text-xl font-anta text-white">{title}</h3>
        <p className="font-karla text-gray-300">{description}</p>
      </CardContent>

      <CardFooter className="p-4">
        <HStack spacing={2}>
          <FontAwesomeIcon icon={faArrowRight} className="text-white" />
          <a 
            href={link} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-white hover:text-cyan-400 transition-colors"
          >
            More info about this
          </a>
        </HStack>
      </CardFooter>
    </ShadCard>
  );
};

export default Card;
